<template>
  <div :class="showVariantSelectScreen ? 'variant-parent-wrapper' : ''">
    <div :class="showVariantSelectScreen ? 'flex' : ''">
      <div :class="showVariantSelectScreen ? 'flex-column' : ''">
        <div v-if="!showVariantSelectScreen">
          <div
            :class="isEventWrapper ? 'wishlist-icon-platform' : 'wishlist-icon'"
            v-if="config.wishlist.active && isMounted"
            :style="[
              config.discount.active && config.wishlist.active
                ? ['top-left', 'top-left-inverted', 'left'].includes(config.discount.position)
                  ? { right: '12px' }
                  : { left: '12px' }
                : config.wishlist.active
                  ? config.wishlist.position === 'right'
                    ? { right: '12px' }
                    : { left: '12px' }
                  : {}
            ]"
            @click="wishit($event, n, productData, accountsData)"
          >
            <div v-if="isEventWrapper ? isWishlisted : isMounted && follow[n.slug]">
              <span id="config.wishlist.color" class="inline-svg">
                <svg
                  :style="{
                    strokeWidth: `var(--wishlistIconBorderWidth)`,
                    stroke: 'var(--wishlistIconBorderColor)'
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 28 28"
                >
                  <g style="transform: translate(2px, 2px)">
                    <path
                      :fill="config.wishlist.color"
                      d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"
                    ></path>
                  </g>
                </svg>
              </span>
            </div>
            <div v-else>
              <span id="config.wishlist.defaultColor" class="inline-svg">
                <svg
                  :style="{
                    strokeWidth: `var(--wishlistIconBorderWidth)`,
                    stroke: 'var(--wishlistIconBorderColor)'
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 28 28"
                >
                  <g style="transform: translate(2px, 2px)">
                    <path
                      :fill="config.wishlist.defaultColor"
                      d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"
                    ></path>
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div class="redirect-a-tag" v-if="isEventWrapper">
            <!-- Todo move to different components -->
            <ImageAndOffer
              :active="config.discount.active"
              :cardLayoutStyle="config.product_card_layout.style"
              :iconUrl="n.medias && n.medias.length ? n.medias[0].url : DEFAULT_IMG"
              :slug="n.slug"
              :productImageScale="true"
              :discountPosition="config.discount.position"
              :discount="n.discount"
            />
            <LabelInvertedHorizontal
              v-if="
                config.discount.active &&
                n.discount != '' &&
                config.discount.position.includes('inverted') &&
                config.discount.text_orientation.includes('horizontal')
              "
              :discountPosition="config.discount.position"
              :discount="n.discount"
            />
            <LabelInvertedVertical
              v-else-if="
                config.discount.active &&
                n.discount != '' &&
                config.discount.position.includes('inverted') &&
                config.discount.text_orientation.includes('vertical')
              "
              :discountPosition="config.discount.position"
              :discount="n.discount"
            />
            <LabelHorizontal
              v-else-if="
                config.discount.active &&
                n.discount != '' &&
                !(
                  config.product_card_layout.style == 'card-layout-1' &&
                  config.discount.position == 'bottom-left'
                )
              "
              :discountPosition="config.discount.position"
              :discount="n.discount"
            />
            <div class="content-box">
              <div v-if="isMounted" class="review">
                <span v-if="config.reviews.show_stars && review_map[n.slug].rating > 0">
                  <!-- full star -->
                  <span
                    v-for="i in Math.floor(review_map[n.slug].rating)"
                    :key="i"
                    class="inline-svg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="20"
                      viewBox="0 0 16 15"
                    >
                      <path
                        d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z"
                        :fill="config.reviews.star_color"
                      ></path>
                    </svg>
                  </span>
                  <!-- half star if any -->
                  <span
                    v-if="Math.floor(review_map[n.slug].rating) % 1 !== 0"
                    class="inline-svg inline-svg-half"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 24 24"
                    >
                      <!-- <g id="star_half_24px"> -->
                      <path
                        id="icon/toggle/star_half_2px"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.8101 9.12L22 9.73999L16.55 14.47L18.1801 21.5L12 17.77L5.81995 21.5L7.45996 14.47L2 9.73999L9.18994 9.13L12 2.5L14.8101 9.12ZM12 6.59998V15.9L15.77 18.18L14.77 13.9L18.09 11.02L13.71 10.64L12 6.59998Z"
                        :fill="config.reviews.star_color"
                      />
                      <!-- </g> -->
                    </svg>
                  </span>
                </span>
                <span
                  v-if="
                    config.reviews.rating_detail.show_rating_average &&
                    review_map[n.slug].rating > 0
                  "
                  class="rating_average"
                >
                  {{ review_map[n.slug].rating }}</span
                >
                <!-- show only reviews -->
                <span
                  v-if="
                    config.reviews.show_count &&
                    !config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].reviews_count > 0
                  "
                  class="review__count"
                >
                  ({{ review_map[n.slug].reviews_count }} Reviews)</span
                >
                <!-- show only ratings -->
                <span
                  v-if="
                    !config.reviews.show_count &&
                    config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].ratings_count > 0
                  "
                  class="total_rating_count"
                >
                  ({{ review_map[n.slug].ratings_count }} Ratings)</span
                >
                <!-- show both ratings and review -->
                <span
                  v-if="
                    config.reviews.show_count &&
                    config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].ratings_count > 0
                  "
                  class="total_rating_count"
                >
                  ({{ review_map[n.slug].ratings_count }} Ratings</span
                >
                <span
                  v-if="
                    config.reviews.show_count &&
                    config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].reviews_count <= 0 &&
                    review_map[n.slug].ratings_count > 0
                  "
                  class="total_rating_count"
                  >)
                </span>
                <span
                  v-if="
                    config.reviews.show_count &&
                    config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].ratings_count > 0 &&
                    review_map[n.slug].reviews_count > 0
                  "
                  class="review__count"
                  >&
                </span>
                <span
                  v-if="
                    config.reviews.show_count &&
                    config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].ratings_count <= 0 &&
                    review_map[n.slug].reviews_count > 0
                  "
                  class="review__count"
                  >(
                </span>
                <span
                  v-if="
                    config.reviews.show_count &&
                    config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].reviews_count > 0
                  "
                  class="review__count"
                >
                  {{ review_map[n.slug].reviews_count }} Reviews)</span
                >
              </div>
              <div
                class="product-name"
                :class="{
                  'product-name-width-trim': config.product_title.multiline_product_name
                }"
                v-if="config.product_title.layout == 'layout1'"
              >
                <span v-if="config.product_title.active" class="brand">{{ n.brand.name }}</span>
                <span v-if="config.product_title.active" class="divider">|</span>
                <span class="name">{{ n.name }}</span>
              </div>
              <div
                class="product-name second"
                :class="{
                  'product-name-width-trim': config.product_title.multiline_product_name
                }"
                v-if="config.product_title.layout == 'layout2'"
              >
                <span v-if="config.product_title.active" class="brand">{{ n.brand.name }}</span>
                <br />
                <span class="name">{{ n.name }}</span>
              </div>

              <PriceBox
                :price="n.price"
                :isPriceActive="config.price.active"
                :priceLayout="config.price.layout"
                :secondaryPriceActive="config.price.secondary_price_active"
              />

              <HighlightsBox
                :isHighlightsActive="config.product_highlights.active"
                :highlights="n.highlights"
                :highlightsCount="config.product_highlights.highlight_count"
              />
            </div>
          </div>
          <a
            v-else
            class="redirect-a-tag"
            :href="`/product/${n.slug}?${utm.name}_source=${utm.source}&${utm.name}_medium=${utm.medium}&item_code=${n.item_code}`"
            target="blank"
          >
            <ImageAndOffer
              :active="config.discount.active"
              :cardLayoutStyle="config.product_card_layout.style"
              :iconUrl="n.medias && n.medias.length ? n.medias[0].url : DEFAULT_IMG"
              :slug="n.slug"
              :productImageScale="true"
              :discountPosition="config.discount.position"
              :discount="n.discount"
            />
            <LabelInvertedHorizontal
              v-if="
                config.discount.active &&
                n.discount != '' &&
                config.discount.position.includes('inverted') &&
                config.discount.text_orientation.includes('horizontal')
              "
              :discountPosition="config.discount.position"
              :discount="n.discount"
            />
            <LabelInvertedVertical
              v-else-if="
                config.discount.active &&
                n.discount != '' &&
                config.discount.position.includes('inverted') &&
                config.discount.text_orientation.includes('vertical')
              "
              :discountPosition="config.discount.position"
              :discount="n.discount"
            />
            <LabelHorizontal
              v-else-if="
                config.discount.active &&
                n.discount != '' &&
                !(
                  config.product_card_layout.style == 'card-layout-1' &&
                  config.discount.position == 'bottom-left'
                )
              "
              :discountPosition="config.discount.position"
              :discount="n.discount"
            />
            <div class="content-box">
              <div v-if="isMounted" class="review">
                <span v-if="config.reviews.show_stars && review_map[n.slug].rating > 0">
                  <!-- full star -->
                  <span
                    v-for="i in Math.floor(review_map[n.slug].rating)"
                    :key="i"
                    class="inline-svg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="20"
                      viewBox="0 0 16 15"
                    >
                      <path
                        d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z"
                        :fill="config.reviews.star_color"
                      ></path>
                    </svg>
                  </span>
                  <!-- half star if any -->
                  <span
                    v-if="Math.floor(review_map[n.slug].rating) % 1 !== 0"
                    class="inline-svg inline-svg-half"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 24 24"
                    >
                      <!-- <g id="star_half_24px"> -->
                      <path
                        id="icon/toggle/star_half_2px"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.8101 9.12L22 9.73999L16.55 14.47L18.1801 21.5L12 17.77L5.81995 21.5L7.45996 14.47L2 9.73999L9.18994 9.13L12 2.5L14.8101 9.12ZM12 6.59998V15.9L15.77 18.18L14.77 13.9L18.09 11.02L13.71 10.64L12 6.59998Z"
                        :fill="config.reviews.star_color"
                      />
                      <!-- </g> -->
                    </svg>
                  </span>
                </span>
                <span
                  v-if="
                    config.reviews.rating_detail.show_rating_average &&
                    review_map[n.slug].rating > 0
                  "
                  class="rating_average"
                >
                  {{ review_map[n.slug].rating }}</span
                >
                <!-- show only reviews -->
                <span
                  v-if="
                    config.reviews.show_count &&
                    !config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].reviews_count > 0
                  "
                  class="review__count"
                >
                  ({{ review_map[n.slug].reviews_count }} Reviews)</span
                >
                <!-- show only ratings -->
                <span
                  v-if="
                    !config.reviews.show_count &&
                    config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].ratings_count > 0
                  "
                  class="total_rating_count"
                >
                  ({{ review_map[n.slug].ratings_count }} Ratings)</span
                >
                <!-- show both ratings and review -->
                <span
                  v-if="
                    config.reviews.show_count &&
                    config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].ratings_count > 0
                  "
                  class="total_rating_count"
                >
                  ({{ review_map[n.slug].ratings_count }} Ratings</span
                >
                <span
                  v-if="
                    config.reviews.show_count &&
                    config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].reviews_count <= 0 &&
                    review_map[n.slug].ratings_count > 0
                  "
                  class="total_rating_count"
                  >)
                </span>
                <span
                  v-if="
                    config.reviews.show_count &&
                    config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].ratings_count > 0 &&
                    review_map[n.slug].reviews_count > 0
                  "
                  class="review__count"
                  >&
                </span>
                <span
                  v-if="
                    config.reviews.show_count &&
                    config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].ratings_count <= 0 &&
                    review_map[n.slug].reviews_count > 0
                  "
                  class="review__count"
                  >(
                </span>
                <span
                  v-if="
                    config.reviews.show_count &&
                    config.reviews.rating_detail.show_total_rating_count &&
                    review_map[n.slug].reviews_count > 0
                  "
                  class="review__count"
                >
                  {{ review_map[n.slug].reviews_count }} Reviews)</span
                >
              </div>
              <div
                class="product-name"
                :class="{
                  'product-name-width-trim': config.product_title.multiline_product_name
                }"
                v-if="config.product_title.layout == 'layout1'"
              >
                <span v-if="config.product_title.active" class="brand">{{ n.brand.name }}</span>
                <span v-if="config.product_title.active" class="divider">|</span>
                <span class="name">{{ n.name }}</span>
              </div>
              <div
                class="product-name second"
                :class="{
                  'product-name-width-trim': config.product_title.multiline_product_name
                }"
                v-if="config.product_title.layout == 'layout2'"
              >
                <span v-if="config.product_title.active" class="brand">{{ n.brand.name }}</span>
                <br />
                <span class="name">{{ n.name }}</span>
              </div>

              <PriceBox
                :price="n.price"
                :isPriceActive="config.price.active"
                :priceLayout="config.price.layout"
                :secondaryPriceActive="config.price.secondary_price_active"
              />

              <HighlightsBox
                :isHighlightsActive="config.product_highlights.active"
                :highlights="n.highlights"
                :highlightsCount="config.product_highlights.highlight_count"
              />
            </div>
          </a>
        </div>
        <!-- Show variant select Dropdown  -->
        <div v-else :class="showVariantSelectScreen ? 'variant-main-wrapper' : ''">
          <div class="variant-wrapper" :style="{ width: customWidth }">
            <div class="close-button" @click="closeVariant">
              <CloseButtonSvg />
            </div>
            <!-- 
            Here we will loop over variantDetails Array which
            will be having one item. 
            -->
            <div v-for="item in variantDetails" :key="item">
              <!-- Condition - Variant is Active -->
              <div
                v-if="functionalConfigAddToCart?.add_to_cart?.variants_setting?.active"
                class="options-dropdown"
              >
                <CustomDropdown
                  v-model="selectedVariants"
                  :placeholder="item?.header"
                  :label="item?.header"
                  :items="item?.values"
                />
                <div class="error-text" v-if="showVariantSelectErrorText">
                  Please select the variant
                </div>
              </div>
              <!-- 
              If user have select any variant from the variant dropdown
              then this size selection dropdown of that variant will be shown 
              -->
              <div v-if="selectedVariants" class="options-dropdown">
                <CustomDropdown
                  v-model="selectedVariantsSize"
                  placeholder="Select Size"
                  label="Select Size"
                  :items="selectedVariantsAvailableSizes"
                />
                <div class="error-text" v-if="showSizeSelectErrorText">Please select the size</div>
              </div>
              <!-- Failed in Add to Cart Info Section  -->
              <div v-if="failedAddingToCart" class="failed-adding-cart-error-text">
                <p>{{ failedAddingToCartErrorMessage }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- CTA Button Here  -->
        <!--  
        Condition - Add To Cart and Variant both are Active and Item has variant
        -->
        <div
          v-if="
            functionalConfigAddToCart?.add_to_cart?.active &&
            functionalConfigAddToCart?.add_to_cart?.variants_setting?.active &&
            n?.variants?.items?.length > 0
          "
        >
          <!-- Show Select Variant Button -->
          <div v-if="showVariantSelectScreen">
            <AddToCartButton
              @addItemToCart="addItemToCart"
              :productData="productData"
              :accountsData="accountsData"
              :element="n"
              :functionalConfigAddToCart="functionalConfigAddToCart"
              :isEventWrapper="isEventWrapper"
              :addedToCart="addedToCart"
              :failedAddingToCart="failedAddingToCart"
              :showSizeSelectErrorText="showSizeSelectErrorText"
              :showVariantSelectErrorText="showVariantSelectErrorText"
            />
          </div>
          <!-- If Clicked on Variant button then open Variant Screen and show Add to Cart Button  -->
          <div v-else class="button-wrapper">
            <button
              v-stroke-btn
              v-ripple
              theme="secondary"
              class="select-variant-button"
              size="small"
              @click="selectVariant"
            >
              {{
                functionalConfigAddToCart?.add_to_cart?.variants_setting?.button_label ||
                'Select Variant'
              }}
            </button>
          </div>
        </div>
        <div
          v-if="
            functionalConfigAddToCart?.add_to_cart?.active &&
            !functionalConfigAddToCart?.add_to_cart?.variants_setting?.active &&
            showVariantSelectScreen
          "
        >
          <!-- Show Add To Cart Button -->
          <AddToCartButton
            @addItemToCart="addItemToCart"
            :productData="productData"
            :accountsData="accountsData"
            :element="n"
            :functionalConfigAddToCart="functionalConfigAddToCart"
            :isEventWrapper="isEventWrapper"
            :addedToCart="addedToCart"
            :failedAddingToCart="failedAddingToCart"
          />
        </div>
        <div
          class="button-wrapper"
          v-if="
            functionalConfigAddToCart?.add_to_cart?.active &&
            !functionalConfigAddToCart?.add_to_cart?.variants_setting?.active &&
            !showVariantSelectScreen
          "
        >
          <!-- Show Add To Cart Button when Variant Toggle is disabled-->
          <!-- <AddToCartButton
            @addItemToCart="addItemToCart"
            :productData="productData"
            :accountsData="accountsData"
            :element="n"
            :functionalConfigAddToCart="functionalConfigAddToCart"
            :isEventWrapper="isEventWrapper"
            :addedToCart="addedToCart"
            :failedAddingToCart="failedAddingToCart"
          /> -->
          <button
            theme="secondary"
            class="add-to-cart-button"
            size="small"
            @click="customSelectVariant"
          >
            {{ functionalConfigAddToCart?.add_to_cart?.button_label || 'Add To Cart' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomDropdown from './CustomDropdown.vue';
import AddToCartButton from './AddToCartButton.vue';
import PriceBox from '../../src/components/PriceBox.vue';
import HighlightsBox from '../../src/components/HighlightsBox.vue';
import LabelInvertedHorizontal from '../../src/components/LabelInvertedHorizontal.vue';
import LabelInvertedVertical from '../../src/components/LabelInvertedVertical.vue';
import LabelHorizontal from '../../src/components/LabelHorizontal.vue';
import CloseButtonSvg from './CloseButtonSvg.vue';
import ImageAndOffer from '../../src/components/ImageAndOffer.vue';
export default {
  name: 'ProductData',
  props: {
    config: Object,
    functionalConfigAddToCart: Object,
    n: Object, // Single Product Details,
    review_map: Object,
    follow: Object,
    productData: Object,
    accountsData: Object,
    utm: Object,
    isMounted: Boolean,
    isEventWrapper: Boolean,
    recomm_slug: String,
    configId: String
  },
  data() {
    return {
      showVariantSelectScreen: false,
      variantDetails: [],
      selectedVariants: 0,
      selectedVariantsAvailableSizes: [],
      selectedVariantsSize: '',
      addedToCart: false,
      failedAddingToCart: false,
      failedAddingToCartErrorMessage: '',
      customWidth: '10px',
      showVariantSelectErrorText: false,
      showSizeSelectErrorText: false,
      isWishlisted: false // Variable for handling wishlist on platform side
    };
  },
  components: {
    AddToCartButton,
    ImageAndOffer,
    PriceBox,
    HighlightsBox,
    LabelInvertedHorizontal,
    LabelInvertedVertical,
    LabelHorizontal,
    CustomDropdown,
    CloseButtonSvg
  },
  mounted() {
    this.variantsProcessing();
    this.showSizeSelectErrorText = false;
    this.showVariantSelectErrorText = false;
  },
  methods: {
    wishit(event, citem, productData, accountsData) {
      if (this.isEventWrapper) {
        this.isWishlisted = !this.isWishlisted;
      } else {
        if (accountsData.is_logged_in) {
          citem.follow = this.follow[citem.slug];
          productData.updateWishList(event, citem);
        } else {
          accountsData.openLogin();
        }
      }
    },
    variantsProcessing() {
      // if (!this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.active) {
      //   let options = [];
      //   for (let i = 0; i < this.n?.sizes.length; i++) {
      //     let obj = {
      //       text: this.n?.sizes?.[i],
      //       value: this.n?.sizes?.[i],
      //       details: this.n?.sizes?.[i]
      //     };
      //     options.push(obj);
      //   }
      //   this.variantDetails = options;
      // } else {
      let resArr = [];
      if (this.n?.variants?.items?.length > 0) {
        let options = [];
        for (let i = 0; i < this.n?.variants?.items.length; i++) {
          let obj = {
            text: this.n?.variants?.items?.[i]?.name,
            value: this.n?.variants?.items?.[i]?.uid,
            details: { ...this.n?.variants?.items?.[i] }
          };
          if (this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.show_image) {
            // This value will always be true
            obj = {
              ...obj,
              logo: this.n?.variants?.items?.[i]?.medias?.[0]?.url
            };
          }
          options.push(obj);
        }
        const res = {
          header: this.n?.variants?.header,
          key: this.n?.variants?.key,
          values: options
        };
        resArr?.push(res);
      }
      this.variantDetails = resArr;
      // }
    },
    async addItemToCart(Obj) {
      if (Obj?.event === 'addedToCart') {
        // this.closeVariant();
        // this.showVariantSelectScreen = true;
        if (!this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.active) {
          this.selectedVariants = this?.n?.uid;
        }
        if (this.showVariantSelectScreen) {
          this.closeVariant();
        } else {
          this.selectVariant();
        }
        return;
      }
      // return
      let citem = Obj?.element;
      let cartData = Obj?.cartData;
      // let accountsData = Obj?.accountsData;

      // if (accountsData.is_logged_in) {
      // Toggle the buttonClicked property to apply the effect

      // Check for Validation
      if (!this.selectedVariants) {
        this.showVariantSelectErrorText = true;
        return;
      } else {
        this.showVariantSelectErrorText = false;
      }
      if (!this.selectedVariantsSize) {
        this.showSizeSelectErrorText = true;
        return;
      } else {
        this.showSizeSelectErrorText = false;
      }

      try {
        const addProductToCart = await cartData?.addToCart({
          items: [
            {
              item_id: this.selectedVariants ? this.selectedVariants : citem?.uid,
              item_size:
                this.selectedVariantsAvailableSizes?.[0]?.value === 'OS'
                  ? 'OS'
                  : this.selectedVariantsSize
                    ? this.selectedVariantsSize
                    : citem?.sizes?.[0], // Todo -> As of now the first ever size available is selected
              quantity: 1 // In this case the default Quantity will be 1

              // Dummy Data Fynd x1 (demostore.hostx1.de)
              // item_id: 7868796,
              // item_size: "XL",
              // quantity: 1
            }
          ],
          buy_now: false
        });
        if (addProductToCart?.success) {
          this.addedToCart = true;
          setTimeout(() => {
            this.closeVariant();
            this.selectedVariants = 0;
            this.selectedVariantsAvailableSizes = [];
            this.selectedVariantsSize = '';
            this.addedToCart = false;
            this.failedAddingToCart = false;
          }, 2000);
        } else {
          this.failedAddingToCart = true;
          this.failedAddingToCartErrorMessage = addProductToCart?.message;
          // Resetting to default
          setTimeout(() => {
            this.closeVariant();
            this.selectedVariants = 0;
            this.selectedVariantsAvailableSizes = [];
            this.selectedVariantsSize = '';
            this.addedToCart = false;
            this.failedAddingToCart = false;
          }, 2000);
        }
      } catch (error) {
        console.error('Failed to add product to cart ::: ', error);
      }
    },
    selectVariant() {
      this.showVariantSelectScreen = true;
      const element = document.getElementById(
        `product-list-item-0-${this.recomm_slug}-${this.configId}`
      );
      if (element) {
        const width = element.offsetWidth;
        this.customWidth = width - 19 + 'px';
      }
      this.$emit('showVariantSelectScreen', {
        showVariantSelectScreen: this.showVariantSelectScreen
      });
    },
    customSelectVariant() {
      // this.selectVariant()
      this.addItemToCart({
        event: 'addedToCart'
      });
    },
    closeVariant() {
      this.showVariantSelectScreen = false;
      this.selectedVariants = 0;
      this.selectedVariantsAvailableSizes = [];
      this.selectedVariantsSize = '';
      this.addedToCart = false;
      this.failedAddingToCart = false;
      this.showSizeSelectErrorText = false;
      this.showVariantSelectErrorText = false;
      this.$emit('showVariantSelectScreen', {
        showVariantSelectScreen: this.showVariantSelectScreen
      });
    }
  },
  watch: {
    selectedVariants(newVal) {
      if (newVal !== '') {
        this.showVariantSelectErrorText = false;
      } else {
        this.showVariantSelectErrorText = true;
      }
      if (!this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.active) {
        let availableSizes = [];
        for (let i = 0; i < this.n?.sizes?.length; i++) {
          const obj = {
            text: this?.n?.sizes?.[i],
            value: this?.n?.sizes?.[i]
          };
          availableSizes?.push(obj);
        }
        this.selectedVariantsAvailableSizes = availableSizes;
      } else {
        let selectedItem = this.n?.variants?.items?.filter((ele) => ele?.uid === newVal)?.[0];
        let availableSizes = [];
        for (let i = 0; i < selectedItem?.sizes?.length; i++) {
          const obj = {
            text: selectedItem?.sizes?.[i],
            value: selectedItem?.sizes?.[i]
          };
          availableSizes?.push(obj);
        }
        this.selectedVariantsAvailableSizes = availableSizes;
      }
    },
    selectedVariantsSize(newVal) {
      if (newVal !== '') {
        this.showSizeSelectErrorText = false;
      } else {
        this.showSizeSelectErrorText = true;
      }
    },
    functionalConfigAddToCart() {
      this.variantsProcessing();
    },
    follow() {}
  }
};
</script>

<style lang="less" scoped>
@import '../less/appbindings.less';

// Note -
// Require to import Nitrozen CSS
// As Nitrozen Dropdown CSS was not reflecting
// @import '@gofynd/nitrozen-vue/dist/nitrozen.css';

::v-deep {
  .nitrozen-dropdown-container .nitrozen-option-image .nitrozen-option-logo {
    height: 48px;
    width: auto;
    padding-right: 8px;
  }
}
</style>
